<template>
    <div class="content">
        <div class="more-info">
            <div class="col col-left">
                <div class="group">
                    <div class="group-header">
                        <h4>Я - активний учасник партійних заходів</h4>
                    </div>
                    <div class="group-block">
                        <div class="action">
                            <div class="action--date">15 лютого</div>
                            <div class="action--name">Суд над Тетяною Чорновол</div>
                            <a href="https://www.facebook.com/asd" target="_blank" class="action--link">https://www.facebook.com/asd</a>
                        </div>
                        <div class="action">
                            <div class="action--date">15 лютого</div>
                            <div class="action--name">Суд над Тетяною Чорновол</div>
                            <a href="https://www.facebook.com/" target="_blank" class="action--link">https://www.facebook.com/</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="group">
                    <div class="group-header">
                        <h4>Я реалізую партійні проекти</h4>
                    </div>
                    <div class="group-block">
                        <div class="action">
                            <div class="action--date">15 лютого</div>
                            <div class="action--name">Суд над Тетяною Чорновол</div>
                            <a href="https://www.facebook.com/" target="_blank" class="action--link">https://www.facebook.com/</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-left" v-if="false">
                <div class="group">
                    <div class="group-header">
                        <h4>Я - волонтер</h4>
                    </div>
                    <div class="group-block">
                        <div class="action">
                            <div class="action--date">15 лютого</div>
                            <div class="action--name">Суд над Тетяною Чорновол</div>
                            <a href="https://www.facebook.com/" target="_blank" class="action--link">https://www.facebook.com/</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="group">
                    <div class="group-header">
                        <h4>Я - блогер</h4>
                    </div>
                    <div class="group-block">
                        <div class="action">
                            <div class="action--date">15 лютого</div>
                            <div class="action--name">Суд над Тетяною Чорновол</div>
                            <a href="https://www.facebook.com/" target="_blank" class="action--link">https://www.facebook.com/</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-left">
                <div class="group">
                    <div class="group-header">
                        <h4>Я залучаю друзів</h4>
                    </div>
                    <div class="presence group-block">
                        <div class="group-block_title">
                            <h4>Відправила запрошень друзям про вступ до партії:</h4>
                            <span class="percent">{{attendance.attendancePercent}}</span>
                        </div>
                        <progress id="file" max="100" :value="attendance.attendancePercent"> 100% </progress>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../components/ui/Accordion";
import Doughnut from "@/components/Doughnut";
import ActivityService from "@/services/ActivityService";

export default {
    name: '',
    components: {
        Accordion,
        Doughnut
    },
    props: ['deputy_id'],
    data() {
        return {
            channelsColor: ["#1a2744", "#F04F23", "#CA9A61", "#EADE1A", "#CA61C5", "#61C4CA", "#6164CA", "#61CA84", "#1D5792"],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                showLines: false,
                animation: {
                    animateRotate: false
                },
                tooltips: {
                    enabled: false
                },
                hover: {mode: null},
                legend: {
                    display: false
                },
            },
            channelsHistory: [],
            attendance: [],
            votes: [],
            actions: [],
            initiatives: [],
            trainings: [],
            channelsStats: []
        }
    },
    async created() {
        let data = await ActivityService.mediaStats(this.deputy_id);
        data = data.sort((a, b) => {
            if(a.count > b.count) {
                return -1;
            } else if(a.count < b.count) {
                return 1;
            }
            return 0;
        });

        await this.setAttendance();
        this.channelsStats = data;
    },
    methods: {
        async setMedia() {
            let data = await ActivityService.media(this.deputy_id);
            data = data.sort((a, b) => {
                if(a.items.length > b.items.length) {
                    return -1;
                } else if(a.items.length < b.items.length) {
                    return 1;
                }
                return 0;
            });

            this.channelsHistory = data;
        },
        async setVotes() {
            this.votes = await ActivityService.votes(this.deputy_id);
        },
        async setInitiatives() {
            this.initiatives = await ActivityService.get(this.deputy_id, 4);
        },
        async setAttendance() {
            this.attendance = await ActivityService.attendance(this.deputy_id);
        },
        async setActions() {
            this.actions = await ActivityService.get(this.deputy_id, 5);
        },
        async setTrainings() {
            this.trainings = await ActivityService.get(this.deputy_id, 6);
        },
        urlify(text) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            text = text.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
                return '&#'+i.charCodeAt(0)+';';
            });

            return text.replace(urlRegex, function(url) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>';
            })
        },
    },
    computed: {
        chartData() {
            let dataset = {
                borderWidth: 0,
                backgroundColor: [],
                data: []
            };

            this.channelsStats.forEach(item => {
                dataset.backgroundColor.push(this.channelsColor[item.id]);
                dataset.data.push(item.count);
            });

            return {datasets: [dataset]};
        }
    }
}
</script>

<style lang="less">
    .chart canvas{
        width: 144px!important;
        height: 144px!important;
        @media screen and (max-width: 1320px) {
            margin: 0 auto 10px auto !important;
            object-fit: cover !important;
        }
    }
</style>

<style lang="less"  scoped>
    .content {
        padding: 80px 40px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;
        background: #F5F5F5;
        @media screen and (max-width: 1440px) {
            padding: 80px 30px 30px 30px;
        }
        @media screen and (max-width: 480px) {
            padding: 20px;
        }
    }

   .group {
        max-width: 480px;
        margin-bottom: 40px;
        &-header {
            font-size: 32px;
            line-height: 40px;
            color: #1A2744;
            position: relative;
            padding-bottom: 20px;
            margin: 0 0 20px 0;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background: #fff;
                opacity: 0.1;
            }
            &:after {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: #000;
                opacity: 0.15;
            }
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
   }.accordion .body {
       height: inherit;
   }

    .group-block {
        max-width: 480px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
    }
    .group-block_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h4 {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }
        @media screen and (max-width: 1360px) {
            font-size: 18px;
        }
    }
    .percent {
        font-weight: normal;
        font-size: 46px;
        line-height: 32px;
        color: #A7DA2A;
    }

    ::-webkit-progress-bar {
        background: #fff;
    }
    ::-webkit-progress-value {
        background: #ffc900;
    }
    ::-moz-progress-bar {
        background: #ffc900;
    }
    progress {
        display: block;
        color: red;
        background: yellow;
        border-radius: 8px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        height: 4px;
        line-height: 21px;
        font-size: 15px;
        font-family: sans-serif;
        text-align: center;
    }



   .statistics {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        @media screen and (max-width: 1320px)  {
            display: block;
        }
   }
   .chart {
       margin-top: 20px;
       margin-right: 20px;
       width: 144px;
       height: 144px;
       @media screen and (max-width: 1320px) {
            margin: 0 auto 10px;
       }

   }
   .statistics-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #9DA3AE;
        }
   }
    .statistics-info {
        max-width: 290px;
        width: 65%;
        @media screen and (max-width: 1320px) {
            max-width: 100%;
            width: 100%;
        }
    }
   .channels-list {
        max-width: 290px;
        width: 100%;
        @media screen and (max-width: 1320px) {
            max-width: 100%;
            width: 100%;
        }
        li {
            list-style: none;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.112px;
            color: #1A2744;
            margin-bottom: 3px;
            display: flex;
            justify-content: space-between;
            span.color{
                display: inline-block;
                width: 20px;
                height: 12px;
                margin-right: 12px;
                border-radius: 12px;
            }
            span {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }
        }
        @media screen and (max-width: 480px)  {
            display: block;
        }
   }

   .channel {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 13px;
        margin-bottom: 10px;
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        &-title {
            font-size: 16px;
            line-height: 24px;
            color: #1A2744;
            margin-bottom: 10px;
        }
        &-top {
            display: flex;
            justify-content: space-between;
        }

        &-link {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.098px;
            text-decoration: underline;
            color: #5082F3;
        }
   }
    .lives {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
    }
   .live-date {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
        margin-bottom: 5px;
   }

    .voting {
        padding-bottom: 23px;
        border-bottom: 1px solid #cdd4e0;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
      .voting:last-child {
      margin-bottom: 0;}

    .votin-info {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .voiting-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1A2744;
    }
    .voting-date {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
    }
    .voting-result {
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 24px;
        text-align: right;
    }
    .voting-result.yes {
         color: #a7da2a;
    }
    .voting-result.abstain {
        color: #ff9623;
    }
    .voting-result.against {
        color: #f40035;
    }

    .more-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 1024px) {
            display: block;
        }
    }

    .col {
        width: calc(50% - 30px);
        @media screen and (max-width: 1440px) {
            width: calc(50% - 15px);
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
    }
    .col-left {
        margin-right: 60px;
        @media screen and (max-width: 1440px) {
            margin-right: 30px
        }
        @media screen and (max-width: 1024px) {
            margin-right: 0;
        }
    }

    .promotion {
        border-bottom: 1px solid #f5f5f5;;
        padding-bottom: 13px;
        margin-bottom: 10px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
        &-info {
        margin-bottom: 3px;
        }
        &-date {
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #9DA3AE;
        }
        &-title {
           font-size: 16px;
           line-height: 24px;
           color: #1A2744;
           margin-bottom: 10px;
        }
        &-link {
         font-weight: normal;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.098px;
         text-decoration: underline;
         color: #5082F3;
        }
    }

    .study {
        padding-bottom: 20px;
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        &-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #1A2744;
        }
        &-author {
            font-size: 14px;
            line-height: 24px;
            color: #9DA3AE;
            letter-spacing: normal;
            white-space: pre-wrap;
        }
    }


    .cards {
        background-color: #f5f5f5;
        height: 100vh;

        .common-block {
            background: #1a2744  ;
              .title {
                    display: none  !important;
                }
        }

        &-wrapper {
            display: flex;
            height: 100vh;
        }


        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-item {
            max-width: 32%;
            height: 256px;
            background: #FFFFFF;
            padding: 40px 40px 24px 40px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;

            &:hover {
                transform: scale(1.01);
                box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
            }

            &_avatar {
                max-width: 70px;
                height: 70px;
                margin-right: 40px;
                width: 30%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            &_inner {
                display: flex;
            }

            &_name {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.14px;
                color: #1A2744;
            }

            &_location {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #1A2744;
                margin-bottom: 9px;
            }

            .positions {
                border-bottom: 1px solid #F5F5F5;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }

            .position {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.112px;
                color: #1A2744;
            }

            &_phone {
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #1A2744;
            }
        }
    }




    @media screen and (max-width: 1920px) {
        .content {
            max-width: 1280px;
            width: 70%;
        }

        .cards-inner {
            max-width: 640px;
            width: 30%;
        }

        .cards-items {
            justify-content: space-between;
        }

        .cards-item {
            max-width: 592px;
            width: 49%;
            margin-right: 0;
        }
    }

    @media screen and (max-width: 1600px) {
        .content {
            max-width: 1280px;
            width: 70%;
            padding: 80px 40px 40px;
        }

        .cards-inner {
            padding: 40px 40px 40px 50px;
        }
    }

    @media screen and (max-width: 1440px) {
        .cards-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
        }

        .filter-title {
            font-size: 20px;
            line-height: 24px;
        }

        .filter-reset {
            font-size: 14px;
            line-height: 18px;
        }
        .group-header {
            font-size: 28px;
            line-height: 34px;
        }
    }
    @media screen and (max-width: 1360px)  {
        .group-header {
            font-size: 20px;
            line-height: 30px;
        }
        .group {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-height: 700px) {

    }

    .action {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--date {
            color: #969696;
            font-size: 90%;
            margin-bottom: 3px;
        }

        &--name {
            font-weight: 700;
            color: #1a2744;
            margin-bottom: 5px;
        }

        &--link {
            color: #5082f3;
        }
    }

</style>

