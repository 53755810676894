<template>
    <section class="cards one-card">
        <div class="cards-wrapper">
            <CommonBlock
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                :inverse="true"
                @clickBack="clickBack"
            >
                <div class="member-info">
                     <div class="member-info_top">
                        <div v-if="user.photo" class="member-avatar">
                            <img :src="user.photo" alt="avatar">
                        </div>
                        <h5 class="member-name">
                            {{ user.first_name }} {{ user.last_name }}
                        </h5>
                    </div>

                    <div v-if="hasSocials" class="info-field">
                        <div class="social-links">
                        <span class="label">Соціальні мережі</span>
                            <a v-if="user.youtube" :href="user.youtube" target="_blank">
                                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.82 0.115C18.0187 0.1825 18.6075 0.36 19.15 1.325C19.715 2.28875 20 3.94875 20 6.8725V6.87625V6.8825C20 9.7925 19.715 11.465 19.1513 12.4187C18.6088 13.3837 18.02 13.5588 16.8213 13.64C15.6225 13.7088 12.6112 13.75 10.0025 13.75C7.38875 13.75 4.37625 13.7087 3.17875 13.6388C1.9825 13.5575 1.39375 13.3825 0.84625 12.4175C0.2875 11.4637 0 9.79125 0 6.88125V6.87875V6.875V6.87125C0 3.94875 0.2875 2.28875 0.84625 1.325C1.39375 0.35875 1.98375 0.1825 3.18 0.11375C4.37625 0.03375 7.38875 0 10.0025 0C12.6112 0 15.6225 0.03375 16.82 0.115ZM13.75 6.875L7.5 3.125V10.625L13.75 6.875Z" />
                                </svg>
                            </a>
                            <a v-if="user.twitter" :href="user.twitter" target="_blank">
                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.2727 2.44315C20.4816 2.78811 19.6387 3.01676 18.7599 3.12778C19.664 2.59118 20.354 1.74795 20.6784 0.731578C19.8355 1.23117 18.9048 1.58406 17.913 1.78099C17.1126 0.933794 15.9718 0.409088 14.7274 0.409088C12.3129 0.409088 10.3691 2.35724 10.3691 4.74551C10.3691 5.08915 10.3984 5.41957 10.4702 5.73413C6.8445 5.55835 3.63631 3.83091 1.48111 1.19945C1.10485 1.8484 0.884148 2.59118 0.884148 3.39079C0.884148 4.89222 1.66193 6.22315 2.8213 6.99369C2.12063 6.98047 1.43325 6.77826 0.850909 6.45973C0.850909 6.47295 0.850909 6.49013 0.850909 6.50731C0.850909 8.61407 2.3626 10.364 4.34495 10.7671C3.98997 10.8636 3.60307 10.9098 3.20155 10.9098C2.92234 10.9098 2.64048 10.894 2.3759 10.8358C2.94095 12.5527 4.54439 13.8149 6.45095 13.8558C4.96718 15.0097 3.08322 15.7049 1.04369 15.7049C0.686045 15.7049 0.343023 15.689 0 15.6454C1.93183 16.8838 4.22131 17.5909 6.69027 17.5909C14.7154 17.5909 19.1029 10.9825 19.1029 5.25436C19.1029 5.06272 19.0963 4.87768 19.087 4.69397C19.9525 4.08335 20.6798 3.32075 21.2727 2.44315Z" />
                                </svg>
                            </a>
                            <a v-if="user.facebook" :href="user.facebook" target="_blank">
                                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.44527 20H2.92964C2.60577 20 2.3437 19.7379 2.3437 19.4141V11.7188H0.585892C0.262025 11.7188 -4.57764e-05 11.4567 -4.57764e-05 11.1328V7.61719C-4.57764e-05 7.29332 0.262025 7.03125 0.585892 7.03125H2.3437V4.10156C2.3437 1.8402 4.1839 0 6.44527 0H9.96089C10.2848 0 10.5468 0.26207 10.5468 0.585938V4.10156C10.5468 4.42543 10.2848 4.6875 9.96089 4.6875H7.0312V7.03125H9.96089C10.1331 7.03125 10.2968 7.1068 10.4078 7.2384C10.5193 7.37 10.5674 7.5434 10.5388 7.71332L9.95284 11.2289C9.90593 11.5116 9.66159 11.7188 9.37492 11.7188H7.03117V19.4141C7.0312 19.7379 6.76913 20 6.44527 20Z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div v-if="user.phone" class="info-field">
                        <span class="label">Номер телефону</span>
                        <p class="description">{{ user.phone }}</p>
                    </div>
                    <div v-if="user.email" class="info-field">
                        <span class="label">Електронна адреса</span>
                        <p class="description">{{ user.email }}</p>
                    </div>
                    <div v-if="user.area" class="info-field">
                        <span class="label">Назва області/міста/отг / села або селища</span>
                        <p class="description">{{ user.area }}</p>
                    </div>
                    <div v-if="user.council" class="info-field">
                        <span class="label">Тереторіальна партійна організація</span>
                        <p class="description">{{ user.council }}</p>
                    </div>
                </div>

            </CommonBlock>
            <MoreInfoAboutMember :deputy_id="user.id" v-if="user.id" />
        </div>
    </section>
</template>


<script>
    import DeputyCardItem from "../components/DeputyCardItem";
    import CommonBlock from "../components/CommonBlock";
    import router from "../router"
    import Accordion from "../components/ui/Accordion";
    import MoreInfoAboutMember from '../components/MoreInfoAboutMember'
    import UserService from "../services/UserService";

    export default {
        components: {
            DeputyCardItem,
            CommonBlock,
            Accordion,
            MoreInfoAboutMember
        },
        data() {
            return {
                breadcrumbs: [
                    { id: 1, name: 'Члени партії', link: '/member' }
                ],
                title: null,
                user: {}
            };
        },
        methods: {
            optionSelect(option) {
                this.selected = option.name;
            },
            clickBack() {
                this.$router.go(-1);
            }
        },
        async created() {
            this.user = await UserService.getDeputy(this.$route.params.id);
            document.title = this.fullName;
        },
        computed: {
            fullName() {
                return this.user.last_name + " " + this.user.first_name + " " + this.user.middle_name;
            },
            hasSocials() {
                return this.user.youtube || this.user.facebook || this.user.twitter;
            }
        }
    };
</script>
<style lang="less" scoped>
.member-info {
    margin-bottom: 50px;
     @media screen and (max-width: 950px) {
         padding-top: 30px;
     }
}
.member-info_top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.member-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.member-name {
    max-width: 221px;
    color: #1a2744;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 32px;
}
.surname {
    font-size: 32px;
    line-height: 32px;
    @media screen and (max-width: 480px) {
       font-size: 26px;
       line-height: 28px;
    }
}

.info-field {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 15px;
    &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #000;
    opacity: 0.15;
    left: 0;
    bottom: 0;
}
&:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 0.1;
    left: 0;
    bottom: -1px;
}
}
.label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #CDD4E0;
    margin-bottom: 5px;
    display: inline-block;
}
.description {
        font-size: 18px;
        line-height: 24px;
        color: #FFC900;
}

.social-links {
    .label {
    margin-bottom: 9px;
    display: block;
}
a {
    margin-right: 20px;
    transition: all .3s ease-in;
    svg {
        fill: #1a2744;
    }
    &:hover {
    svg {
        fill: #fff;
    }
    }
    }
}

.cards {
    background-color: #f5f5f5;
    height: 100vh;
    @media screen and (max-width: 992px) {
        height: 100%;
    }

    .common-block {
         background: #1a2744 ;
     &-title {
         display: none !important;
    }
}

&-wrapper {
    display: flex;
    height: 100vh;
    @media screen and (max-width: 992px) {
        display: block;
        height: 100%;
    }
}

&-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

&-item {
    max-width: 32%;
    min-height: 256px;
    background: #FFFFFF;
    padding: 40px 40px 24px 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
    transform: scale(1.01);
    box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
    }

    &_avatar {
    width: 70px;
    height: 70px;
    margin-right: 40px;

    img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    }
}

&_inner {
display: flex;
}

&_name {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.14px;
    color: #1A2744;
}

&_location {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1A2744;
    margin-bottom: 9px;
}

.positions {
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.position {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.112px;
    color: #1A2744;
}

    &_phone {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #1A2744;
    }
}
}



@media screen and (max-width: 1920px) {
    .content {
        max-width: 1280px;
        width: 70%;
    }

    .cards-inner {
        max-width: 640px;
        width: 30%;
    }

    .cards-items {
        justify-content: space-between;
    }

    .cards-item {
        max-width: 592px;
        width: 49%;
        margin-right: 0;
    }
}

@media screen and (max-width: 1600px) {
    .content {
        max-width: 1280px;
        width: 70%;
        padding: 80px 30px 30px 30px;
    }

    .cards-inner {
        padding: 40px 40px 40px 50px;
    }
}

@media screen and (max-width: 1440px) {
    .cards-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .filter-title {
        font-size: 20px;
        line-height: 24px;
    }

    .filter-reset {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (max-width: 992px) {
    .content {
        width: 100%;
        height: 100%;
        padding: 20px;
    }
}

@media screen and (max-height: 700px) {

}

</style>

<style lang="less" >
.one-card {
    .common-block {
        .title {
            display: none
        }
    }
}
</style>

